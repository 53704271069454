@font-face {
    font-family: "Neue-Plak-Extended-Black";
    src: url("./fonts/Neue Plak Extended Black.ttf") format("truetype");
}

@font-face {
    font-family: "Neue-Plak-Extended-Regular";
    src: url("./fonts/Neue Plak Extended Regular.ttf") format("truetype");
}

:root {
    --atg-black: #000000;
    --atg-white: #FFFFFF;
}

body {
    background-color: var(--atg-black);
    color: var(--atg-white);
}

h1, h2 {
    margin: 0;
}