.app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-height: 100vh;

    .header {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 88px;

        // iPhone
        @media only screen and (min-device-width: 300px) and (max-device-width: 480px) {
            margin-bottom: 50px;
        }

        h1 {
            font-family: "Neue-Plak-Extended-Black";
            font-size: 112px;
            letter-spacing: 6px;
            text-align: center;

            // iPhone
            @media only screen and (min-device-width: 300px) and (max-device-width: 480px) {
                font-size: 32px;
            }
        }
    }

    .logo {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 112px;

        // iPhone
        @media only screen and (min-device-width: 300px) and (max-device-width: 480px) {
            margin-bottom: 64px;
        }

        img {
            width: 15%;

            // iPhone
            @media only screen and (min-device-width: 300px) and (max-device-width: 480px) {
                width: 40%;
            }

            // iPhone (Landscape)
            @media only screen and (min-device-width: 300px) and (max-device-width: 480px) and (orientation: landscape) {
                width: 20%;
            }
        }
    }

    .subheader {
        display: flex;
        justify-content: center;
        width: 100%;

        h2 {
            display: flex;
            justify-content: center;
            width: 35%;

            a,
            span {
                font-family: "Neue-Plak-Extended-Regular";
                font-size: 32px;
                letter-spacing: 2px;
                text-decoration: none;
                color: var(--atg-white);

                // iPhone
                @media only screen and (min-device-width: 300px) and (max-device-width: 480px) {
                    font-size: 12px;
                }
            }

            .divider {
                margin: 0 32px;

                // iPhone
                @media only screen and (min-device-width: 300px) and (max-device-width: 480px) {
                    margin: 0 8px;
                }
            }
        }
    }
}
